import { Injectable } from '@angular/core';
import { ToastData } from '@core/interfaces/toast.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class ToastService {
  private toastSubject = new BehaviorSubject<ToastData | null>(null);

  private isToastVisibleSubject = new BehaviorSubject<boolean>(false);

  public toast$ = this.toastSubject.asObservable();

  public isToastVisible$ = this.isToastVisibleSubject.asObservable();

  public showToast(message: string, type: 'success' | 'error'| 'info' | 'warning'): void {
    this.toastSubject.next({ message, type });
    this.isToastVisibleSubject.next(true);
  }

  public hideToast(): void {
    this.toastSubject.next(null);
    this.isToastVisibleSubject.next(false);
  }
}
